import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { FooterLinks } from "./FooterLinks";
import { CDN_HOST } from "env/env.dev";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation("footer");
  const home = "/";
  const Logo = `${CDN_HOST}/icons/icon-logo-desktop.svg`;
  
  return (
    <div className="Footer">
      <div className="wrapper">
       <div className="logo-section">
        <a href={home} rel="noopener noreferrer">
         <img src={Logo} alt="Logo" width="171" height="50" loading="lazy" ></img>
        </a>
       </div>
       <div className="main-section">
          <div className="company-section">
            <p className="title">{t("footer:company")}</p>
           <ul>
            <li>
              <Link to="/who-we-are">{t("footer:whoWeAre")}</Link>
            </li>
            {/* <li>
            <a href="/" aria-label="Our License" target="_blank" rel="noopener noreferrer">{t("footer:ourLicense")}</a>
            </li> */}
            <li>
              <Link to="/contact-us">{t("footer:contactUs")}</Link>
            </li>
           </ul>
          </div>
          <div className="join-section">
            <p className="title">{t("footer:joinUs")}</p>
            <ul>
              <li>
              <Link to="/register" aria-label="Become a member" rel="noopener noreferrer">{t("footer:becomeAMember")}</Link>
              </li>
              <li>
              <p>{t("footer:followUs")}</p>
              </li>
              <li>
                <FooterLinks/>
              </li>
            </ul>
          </div>
       </div>
       <div className="bottom-section">
       {/* <Link to="/terms-and-conditions" aria-label="Terms and Conditions" rel="noopener noreferrer">{t("footer:termsAndConditions")}</Link> */}
       <Link to="/privacy-policy" aria-label="Privacy Policy"  rel="noopener noreferrer">{t("footer:privacyPolicy")}</Link>
       {/* <Link to="/cookies-policy" aria-label="Cookies policy" rel="noopener noreferrer">{t("footer:cookies")}</Link> */}
       </div>
      </div>
    </div>
  );
};
export default Footer;