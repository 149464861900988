import { useTranslation } from 'react-i18next';
import { SocialMedia } from 'components/views/WhoWeAre/index'

import './ContactMessage.scss';

const ContactMessage = () => {
    const { t } = useTranslation(["contact"]);

    return (
        <section className="ContactMessage">
            <h1>{t("contactSubtitle")}</h1>
            <p>{t("contactEmail")}<a href="mailto:info@dreamtosucceed.us" rel="noopener noreferrer">info@dreamtosucceed.us</a></p>
            <br />
            <p>{t("contactSocialText")}</p>
            
            <SocialMedia />
        </section>
    )
}

export default ContactMessage;
