import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';

import './VolunteerSection.scss';
import { Link } from 'react-router-dom';


interface VolunteerSectionProps {
    formURL?: string
}

const VolunteerSection = ( {
    formURL
} : VolunteerSectionProps) => {
    const { t } = useTranslation(["members"]);
    const VolunteerIMG = `${CDN_HOST}/images/volunteer.png`;

    return (
        <section className="VolunteerSection">
            <div className="wrapper">
                <div className="Volunteer__content">
                    <h2>{t("volunteerTitle")}</h2>
                    <p>{t("volunteerTextOne")}</p>
                    <p>{t("volunteerTextTwo")}</p>
        
                    <div className="button__container">
                    <p>{t("becomeVolunteerButton")}</p>
                        <Link to={formURL} className="btn-primary" rel="noopener noreferrer">{t("joinButton")}</Link>
                    </div>
                </div>

                <div className="images-container">
                    <div className="Volunteer__image-container">
                        <figure className="Volunteer__image">
                            <img src={VolunteerIMG} loading="lazy" alt="Volunteers" />
                        </figure>
                    </div>

                    <div className="techNode-section"></div>
                </div>
            </div>
        </section>
    )
}

export default VolunteerSection;
