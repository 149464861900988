import { useEffect, useState } from "react";
import Loading from "./Loading";

const LoadingAnimation = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {isLoading ? <Loading /> : null}
    </div>
  );
};

export default LoadingAnimation;

