import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import "./SocialMediaCard.scss";

interface SocialMediaCardProps {
  icon: string;
  iconName: string;
  link: string;
  numberOfFollowers: number;
}

const SocialMediaCard: React.FC<SocialMediaCardProps> = ({
  icon,
  iconName,
  link,
  numberOfFollowers,
}) => {
  const { t, i18n: { language } } = useTranslation(["whoWeAre"]);
  const [count, setCount] = useState(0);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const increment = Math.ceil(numberOfFollowers / 200);
            let currentCount = 0;

            const interval = setInterval(() => {
              currentCount += increment;
              setCount((prevCount) => {
                const newCount = prevCount + increment;
                return newCount >= numberOfFollowers
                  ? numberOfFollowers
                  : newCount;
              });

              // Detener la animación cuando se alcanza el número completo
              if (currentCount >= numberOfFollowers) {
                clearInterval(interval);
              }
            }, 15);

            // Detener la animación cuando el elemento ya no está visible
            if (cardRef.current) {
              const cardRect = cardRef.current.getBoundingClientRect();
              const cardBottom = cardRect.top + cardRect.height;

              if (
                cardBottom >
                  window.innerHeight + cardRect.height * 0.6 || // Umbral del 60%
                cardRect.top < -cardRect.height * 0.6
              ) {
                clearInterval(interval);
              }
            }

            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.6 } // Se activa cuando el elemento es 100% visible
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect();
  }, [numberOfFollowers, language]);

  useEffect(() => {
    setCount(0); // Reiniciar el conteo al cambiar el idioma
  }, [language]);

  return (
    <div ref={cardRef} className="SocialMediaCard">
      <img className="icon" src={icon} alt={iconName} />
      <span className="number-followers">{count}</span>
      <span className="followers">{t("followers")}</span>
      <a
        aria-label={iconName}
        className="btn"
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("followUs")}
      </a>
    </div>
  );
};

export default SocialMediaCard;
