import React from "react";
import { useTranslation } from "react-i18next";
import "./JoinTheFamily.scss";

const JoinTheFamily: React.FC = () => {
  const { t } = useTranslation(["whoWeAre"]);

  return (
    <div className="JoinTheFamily">
      <div className="cloud-wrapper">
        <div className="empty-space" />
        <div className="sara-info">
          <h2 className="title">Sara Nunez</h2>
          <p className="charge">{t("saraCharge")}</p>
          <div className="quote">
            <div className="quote-img" />
            {t("saraQuote")}
          </div>
        </div>
      </div>
      <div className="join-info">
        <h2 className="title">{t("joinTheFamily")}</h2>
        <p className="description">{t("joinTheFamilyDescription")}</p>
        <a
          className="btn"
          href="https://docs.google.com/forms/d/e/1FAIpQLScsU5gyZzUukzNfkwUhsZ5Btf_bzq7nC3dw0HazXznz7xKvqQ/viewform"
        >
          {t("joinUs")}
        </a>
      </div>
    </div>
  );
};

export default JoinTheFamily;
