import firebase from "firebase/app";
import "firebase/auth";
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
} from "env/env.dev";

// Configuration
const config = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

// Get firebase instance
const firebaseApp: firebase.app.App = firebase.initializeApp({
  ...config,
});

// Setting up social providers
// Google
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// Facebook
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
facebookAuthProvider.addScope("email");
facebookAuthProvider.addScope("public_profile");
export { facebookAuthProvider };

// Util functions

export const getCurrentUser = () => {
  const user = firebaseApp.auth().currentUser;
  console.log("getCurrentUser", user);
  if (user) {
    return user;
  } else {
    return null;
  }
};

export const isLoggedIn = () => {
  const user = getCurrentUser();
  console.log("isLoggedIn", user);
  if (user) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentUserToken = async () => {
  const user = firebaseApp.auth().currentUser;
  console.log("getCurrentUserToken", user);
  if (user) {
    const token = await user.getIdToken();
    return `Bearer ${token}`;
  } else {
    return null;
  }
};

export default firebaseApp;
