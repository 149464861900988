import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';

import './JoinUsHome.scss';
import { Link } from 'react-router-dom';


const JoinUsHome = () => {
    const { t } = useTranslation(["homeTranslations"]);
    const  joinUsIMG =  `${CDN_HOST}/images/teamwork.png`;

    return (
        <section className="JoinUsHome">
            <div className="wrapper">
                <div className="join__content-wrapper">
                    <h3>{t("joinUsTitle")}</h3>
                    <p>{t("joinUsContent")}</p>
                    <Link to="/register" aria-label='Join the us as a member' rel="noopener noreferrer">{t("joinUsButton")}</Link>
                </div>
        
                <figure className="joinus__image">
                    <img src={ joinUsIMG } alt="Join us" width="454" height="380" loading="lazy" decoding='async'/>
                </figure>
            </div>
        </section>
    )
};


export default JoinUsHome;