import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
//import { CDN_HOST } from "env/env.dev";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    saveMissing: true,
    supportedLngs: ["en", "es"],
    react: {
      useSuspense: false
    },
    /* backend: {
      loadPath: `${CDN_HOST}/public/locales/{{lng}}/{{ns}}.json`,
    } */
  },
  (err) => {
    if(err) return console.error(err);
  });

export default i18next;