import React from "react";
import { useTranslation } from "react-i18next";
import { TeamCard } from "components/molecules";
import { teamMock, leadershipTeamMock } from "data";
import "./MeetTheTeam.scss";

const MeetTheTeam: React.FC = () => {
  const { t } = useTranslation(["whoWeAre"]);

  const startDate = new Date(2021, 0, 1);
  const currentYear = new Date().getFullYear();
  const dateText = `${startDate.getFullYear()} - ${currentYear}`;

  return (
    <div className="MeetTheTeam">
      <div className="wrapper">
        <h2 className="title">{t("meetTheTeam")}</h2>
        <h3 className="subtitle">
          {t("boardOfDirectors")} <span>{dateText}</span>
        </h3>
        <div className="cards-container">
          {teamMock.map((member, idx) => (
            <TeamCard
              key={idx}
              name={member.name}
              position={member.position}
              quote={member.quote}
              image={member.image}
            />
          ))}
        </div>
        <h4 className="subtitle mt-40">{t("dtsLeadershipTeam")}</h4>
        <div className="cards-container">
          {leadershipTeamMock.map((member, idx) => (
            <TeamCard
              key={idx}
              name={member.name}
              position={member.position}
              image={member.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeam;
