import React from "react";

import "./Testimonial.scss";

interface TestimonialProps {
  testimonial: {
    id: number;
    content: string;
    image: string;
  };
}

const Testimonial: React.FC<TestimonialProps> = ({ testimonial }) => {
  const { id, content, image } = testimonial;

  return (
    <div className="Testimonial">
      <img
        className="user-image"
        src={image}
        alt="Testimonial"
        width="120"
        height="120"
        loading="lazy"
      />
      <div className="testimonial-wrapper">
        <div className="quote" />
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Testimonial;
