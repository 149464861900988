import userIMG from './UserPictureImage/user.png';

import './UserPicture.scss';

const UserPicture = () => {
    return (
        <div className='UserPicture'>
            <p>Jonh Smith</p>
            <figure className='user-photo'>
                <img src={userIMG} alt="User pic" />
            </figure>
        </div>
    )
}

export default UserPicture;
