import { CDN_HOST } from "env/env.dev";

const EdgarRivera     = `${CDN_HOST}/images/EdgarRivera.png`,
      KristopherHoce  = `${CDN_HOST}/images/KristopherHoce.png`,
      Sayten          = `${CDN_HOST}/images/Sayten.png`,
      AndrewFeldsohn  = `${CDN_HOST}/images/AndrewFeldsohn.png`,
      SaraNunez       = `${CDN_HOST}/images/SaraNunez.png`,
      AnaOssa         = `${CDN_HOST}/images/AnaOssa.png`,
      KarenLee        = `${CDN_HOST}/images/KarenLee.png`;

const teamMock = [
  {
    name: "Sara Nunez",
    position: "saraCharge",
    image: SaraNunez,
    quote: "saraQuote",
  },
  {
    name: "Kristopher Hoce",
    position: "kristopherCharge",
    image: KristopherHoce,
    quote: "kristopherQuote",
  },
  {
    name: "Karen Lee",
    position: "karenCharge",
    image: KarenLee,
    quote: "karenQuote",
  },
  {
    name: "Edgar Rivera",
    position: "edgarCharge",
    image: EdgarRivera,
    quote: "edgarQuote",
  },
  {
    name: "Ana Ossa",
    position: "annaCharge",
    image: AnaOssa,
    quote: "annaQuote",
  },
  {
    name: "Andrew Feldsohn",
    position: "andrewCharge",
    image: AndrewFeldsohn,
    quote: "andrewQuote",
  },
  {
    name: "Satyen Harvé",
    position: "saytenCharge",
    image: Sayten,
    quote: "saytenQuote",
  },
];

export default teamMock;
