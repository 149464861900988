import React from "react";
import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";

import "./Map.scss";

const Map: React.FC = () => {
  const { t } = useTranslation(["whoWeAre"]);
  const TaxIcon       = `${CDN_HOST}/images/icon-tax.png`,
        Email         = `${CDN_HOST}/images/icon-email.png`,
        AddressIcon   = `${CDN_HOST}/images/icon-map.png`,
        PhoneIcon     = `${CDN_HOST}/images/icon-phone.png`;

  return (
    <a href="https://goo.gl/maps/LoWCuoW8oiSRAywQ7?coh=178571&entry=tt"
       target="_blank"
       rel="noopener noreferrer"
    >
      <div className="Map">
        <div className="info-container">
          <h2 className="title">{t("ourInformation")}</h2>
          <div className="box address">
            <h5>
              {" "}
              <img src={AddressIcon} alt={t("registeredAddress")} loading="lazy" />
              {t("registeredAddress")}
            </h5>
            <p>Morris Plains, New Jersey, USA</p>
          </div>

          <div className="box email">
            <h5>
              <img src={Email} alt={t("emailAddress")} loading="lazy" />
              {t("emailAddress")}
            </h5>
            <p> info@dreamtosucceed.us</p>
          </div>

          <div className="box phone-number">
            <h5>
              <img src={PhoneIcon} alt={t("phoneNumber")} loading="lazy"/>
              {t("phoneNumber")}
            </h5>
            <p>(+1) 201-707-0000</p>
          </div>

          <div className="box tax">
            <h5>
              <img src={TaxIcon} alt={t("usaTaxId")} loading="lazy" />
              {t("usaTaxId")}
            </h5>
            <p>81-1511637</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Map;
