import classnames from "classnames";

import "./VideosCards.scss";

interface VideosCardsProps {
  title: string;
  text: string;
  urlVideo: string;
  altText?: string;
  position?: 'left';
}

const VideosCards = ({ title, text, urlVideo, altText = title, position }: VideosCardsProps) => {
  return (
    <div className={classnames("VideosCards", {
      left: position === "left"
    })}>
    <iframe src={urlVideo} title={altText} loading='lazy'></iframe>
    <div className="info-container">
    <p className="title">{title}</p>
    <p className="description">{text}</p>
    </div>
    </div>
  );
};

export default VideosCards;