import { useState, useEffect } from 'react';
import { Event } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import { EventModel } from 'components/models/eventModel';
import { API_URL } from 'env/env.dev';
import './UpcomingEvents.scss';

const UpcomingEvents = () => {
  const { t, i18n } = useTranslation(["homeTranslations"]);
  const { t: _t } = useTranslation(["events"]);
  const [eventList, setEventList] = useState<EventModel[]>([]);
  const [showTitlle, setShowTitlle] = useState<boolean>(false);

  useEffect(() => {
    const fetchPastEvents = async (language: string) => {
      try {
        const response = await fetch(`${API_URL}/events/last-events?language=${language}`);
        const data: { data: EventModel[] } = await response.json();
        setEventList(data.data);
        setShowTitlle(true);
      } catch (error) {
        console.error('Error fetching past events', error);
        setShowTitlle(false);
      }
    };

    fetchPastEvents(i18n.language);

    const onLanguageChange = () => {
      fetchPastEvents(i18n.language);
    };

    i18n.on('languageChanged', onLanguageChange);

    return () => {
      i18n.off('languageChanged', onLanguageChange);
    };
  }, [i18n]);

  return (
    <section className="UpcomingEvents">
      <div className="wrapper">
        {showTitlle && <h3>{_t("pastEventsTitle")}</h3> }
        <div className="events-section">
          {eventList.map((event, index) => (
            <Event
              date={event.date}
              description={event.description}
              title={event.title}
              time={event.time}
              typeEvent={event.typeEvent}
              imageEvent={event.image}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
