import { useState } from 'react';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './DreamerCard.scss';


const DreamerCard = () => {
    const { t } = useTranslation(["homeTranslations"]);
    const techRedIMG       = `${CDN_HOST}/images/tech-red.png`,
          techBlueIMG      = `${CDN_HOST}/images/tech-blue.png`,
          dreamerGrayIMG   = `${CDN_HOST}/images/dreamer-gray.png`,
          dreamerColorIMG  = `${CDN_HOST}/images/dreamer.png`;


    const [techIMG, setTechIMG] = useState(techBlueIMG);
    const [dreamerIMG, setDreamerIMG] = useState(dreamerGrayIMG);


    const handleCardClosed = () => {
        setTechIMG(techBlueIMG);
        setDreamerIMG(dreamerGrayIMG);
    };


    const handleCardOpen = () => {
           setTechIMG(techRedIMG);
           setDreamerIMG(dreamerColorIMG);
        
    };

    return (
        <div onMouseOver={handleCardOpen} onMouseOut={handleCardClosed} className="DreamerCard">
            <figure className="tech-image">
                <img src={techIMG} alt="Tech Images" width="225" height="140" loading="lazy" />
            </figure>

            <h3>{t("cardTitleDreamer")}</h3>

            <div id="dreamer__content">
                <p>{t("cardContent")}</p>
                <Link to="/members/dreamer" className="btn-secundary" rel="noopener noreferrer">{t("cardButton")}</Link>
            </div>

            <figure className="Dreamer__image">
                <img src={dreamerIMG} alt="Be a Dreamer" width="308" height="312" loading="lazy" />
            </figure>
            
        </div>
    )
};

export default DreamerCard;