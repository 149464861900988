import { useState } from 'react';
import { CDN_HOST } from 'env/env.dev';
import { Link } from 'react-router-dom';

import './VolunteerCard.scss';
import { useTranslation } from 'react-i18next';

const VolunteerCard = () => {
    const { t } = useTranslation(["homeTranslations"]);
    const techRedIMG          = `${CDN_HOST}/images/tech-red.png`,
          techBlueIMG         = `${CDN_HOST}/images/tech-blue.png`,
          volunteerGrayIMG    = `${CDN_HOST}/images/volunteer-gray.png`,
          volunteerColorIMG   = `${CDN_HOST}/images/volunteer.png`;
          
    const [techIMG, setTechIMG] = useState(techBlueIMG);
    const [volunteerIMG, setVolunteerIMG] = useState(volunteerGrayIMG);

    const handleCardClosed = () => {
        setTechIMG(techBlueIMG);
        setVolunteerIMG(volunteerGrayIMG);
    }

    const handleCardOpen = () => {
        setTechIMG(techRedIMG);
        setVolunteerIMG(volunteerColorIMG);
    };

    return (
        <div onMouseOver={handleCardOpen}  onMouseOut={handleCardClosed} className="VolunteerCard">
            <figure className="tech-image">
                <img src={techIMG} alt="Tech images" width="225" height="140" loading="lazy"  />
            </figure>

            <h3>{t("cardTitleVolunteer")}</h3>

            <div id="volunteer__content">
                <p>{t("cardContent")}</p>
                <Link to="/members/volunteer" className="btn-secundary" rel="noopener noreferrer">{t("cardButton")}</Link>
            </div>

            <figure className="Volunteer__image">
                <img src={volunteerIMG} alt="Be a mentor"  width="270" height="278" loading="lazy"  />
            </figure>
        </div>
    )
};

export default VolunteerCard;