import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "components/atoms";
import { Header, HomeDonation, Map } from "components/molecules";
import {
  Awards,
  JoinTheFamily,
  MeetTheTeam,
  OurVolunteers,
  SocialMedia,
  TheFamily,
  WeAre,
} from "./index";

import "./WhoWeAre.scss";

const WhoWeAre: React.FC = () => {
  const { t } = useTranslation(["whoWeAre"]);
  return (
    <div className="WhoWeAre">
      <ScrollToTop />
      <Header pageTitle={t("whoWeAre")} pageView="who we are" dark/>
      <WeAre />
      <MeetTheTeam />
      <TheFamily />
      <OurVolunteers />
      <SocialMedia />
      <Awards />
      <JoinTheFamily />
      <Map />
      <HomeDonation />
    </div>
  );
};

export default WhoWeAre;
