import { Link } from "react-router-dom";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import "./DonationsCards.scss";
import rectangle from "./Tech Nods/Horizontal.svg"

interface DonationsCardsProps {
  title: string;
  text: string;
  urlImg: string;
  altText?: string;
  isSmall?: boolean;
  link?: string;
}

const DonationsCards = ({ title, text , urlImg, altText, isSmall, link}: DonationsCardsProps) => {
  const { t } = useTranslation(["donations"]);
  return (
    <div className={classNames("DonationsCards", {"is-small": isSmall})}>
    <img src={urlImg} alt={altText} />
    <div className="title">{title}</div>
    <div className="description">{text}</div>
    <div className="btn-container">
    <Link to={link} className="btn-card">{t("buttonDonationCard")}</Link>
    <img src={rectangle}  alt="rectangle"/>
    </div>
    </div>
  );
};

export default DonationsCards;