import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./ShareLinks.scss";

interface ShareLinksProps {
  link?: string;
}

const ShareLinks = ({ link }: ShareLinksProps) => {
  const { i18n, t } = useTranslation(["donations"]);

  const socialLinks = {
    en: {
      twitter: "https://twitter.com/DREAMTOSUCCEED",
      linkedin: "https://www.linkedin.com/company/dream-to-succeed-us/",
      facebook: "https://www.facebook.com/dreamtosucceedus1",
      youtube: "https://www.youtube.com/channel/UCqa_f3kUNHfM5GdzxKVYxzw",
    },
    es: {
      twitter: "https://twitter.com/sonarescrecer",
      linkedin: "https://www.linkedin.com/company/fundaci%C3%B3n-so%C3%B1ar-es-crecer/",
      facebook: "https://www.facebook.com/sonarescrecerorg",
      youtube: "https://www.youtube.com/channel/UCqa_f3kUNHfM5GdzxKVYxzw",
    },
  };

  const currentLanguage = i18n.language;

  const twitter = `${CDN_HOST}/images/twitter-x-logo-circle.webp`;
  const linkedin = `${CDN_HOST}/icons/icon-linkedin.svg`;
  const facebook = `${CDN_HOST}/icons/icon-facebook.svg`;
  const youtube = `${CDN_HOST}/icons/icon-youtube.svg`;
  const share = `${CDN_HOST}/images/share.png`;

  const copyToClipboard = () => {
    const value = "https://www.dreamtosucceedfoundation.org/";
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="ShareLinks">
      <div className="button-container">
        <Link to={link} className="support-btn">
          {t("supportUs")}
        </Link>
      </div>
      <div className="social-links-container">
      <p className="text">{t("share")}</p>
        <div className="social-links">
          <a
            href={socialLinks[currentLanguage].twitter}
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            <img src={twitter} alt="Twitter" loading="lazy"/>
          </a>
          <a
            href={socialLinks[currentLanguage].linkedin}
            aria-label="Linkedin"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            <img src={linkedin} alt="LinkedIn" loading="lazy"/>
          </a>
          <a
            href={socialLinks[currentLanguage].facebook}
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            <img src={facebook} alt="Facebook" loading="lazy"/>
          </a>
          <a
            href={socialLinks[currentLanguage].youtube}
            aria-label="Youtube"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            <img src={youtube} alt="Youtube" loading="lazy" />
          </a>
          <div className="link copy" onClick={() => copyToClipboard()}>
            <img src={share} alt="Share" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLinks;


