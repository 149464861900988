import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';
import { BrandLogosType } from 'components/models/interface-models';

import './Supporters.scss';



const Supporters = () => {

    const { t } = useTranslation(["homeTranslations"]);
    const  GoogleIMG         = `${CDN_HOST}/images/Google.png`,
           salesForceIMG     = `${CDN_HOST}/images/salesforce.png`,
           finastraIMG       = `${CDN_HOST}/images/finastra.png`,
           socialProjectInstituteIMG = `${CDN_HOST}/images/social-project-institute.png`,
           techSoupIMG = `${CDN_HOST}/images/techsoup.png`,
           MicrosoftIMG = `${CDN_HOST}/images/Microsoft.png`,
           socialImpactIMG = `${CDN_HOST}/images/social-impact-oracle.png`,
           evolveIMG = `${CDN_HOST}/images/Evolve.png`,
           estenoIMG = `${CDN_HOST}/images/Esteno.png`,
           MicrosoftAzureIMG = `${CDN_HOST}/images/Microsoft-Azure.png`;

    const supportersIMG: BrandLogosType[] = [ 
        {
            image: GoogleIMG,
            brand: "Google"
        },
        {
            image: salesForceIMG, 
            brand: "Sales Force"
        },
        {
            image: finastraIMG,
            brand: "Finastra"
        },
        {
            image: socialProjectInstituteIMG,
            brand: "Social Project Institute"
        },
        {
            image: techSoupIMG,
            brand: "Tech Soup"
        },
        {
            image: MicrosoftIMG,
            brand: "Microsoft"
        },
        {
            image: socialImpactIMG,
            brand: "Social Impact"
        },
        {
            image: evolveIMG,
            brand: "Envolve"
        },
        {
            image: estenoIMG,
            brand: "Esteno"
        },
        {
            image: MicrosoftAzureIMG,
            brand: "Microsoft Azure"
        }
    ];

    return (
        <section className="Supporters">
            <div className="wrapper">
                <h3>{t("supportersTitle")}</h3>
                <div className="supporters-section">
                    { supportersIMG.map( ({image, brand}, index) => (
                        <figure key={brand + index} className="brand__supporter">
                            <img src={ image } alt={brand} title={brand} width="100" height="50" loading="lazy" decoding='async'/>
                        </figure>
                    )) }
                </div>
            </div>
        </section>
    )
};

export default Supporters;