import classnames from "classnames";
import {useTranslation} from "react-i18next";

import "./ProgramsCards.scss";

interface ProgramsCardsProps {
  title: string;
  text: string;
  text2?: string;
  urlImg: string;
  altText?: string;
  position?: string;
}

const ProgramsCards = ({ title, text ,text2, urlImg, altText, position }: ProgramsCardsProps) => {
  const { t } = useTranslation(["ourPrograms"]);
  return (
    <div className={classnames("ProgramsCards", {
      left: position === "left"
    })}>
    <img src={urlImg} alt={altText} />
    <div className="info-container">
    <p className="title">{title}</p>
    <p className="description">{text}</p>
    <p className="description">{text2}</p>
    </div>
    </div>
  );
};

export default ProgramsCards;