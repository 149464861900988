import "./Loading.scss";
import { useTranslation } from 'react-i18next';
import classNames from "classnames";

const Loading = () => {
  const {i18n} = useTranslation ();
    return (
      <div className="container">
        <div className="loader">
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className= {classNames({
            "loader--text-en":i18n.language==="en",
            "loader--text-es":i18n.language==="es",
          })} />
        </div>
      </div>
    );
  };
  
export default Loading;
