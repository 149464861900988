import React from "react";

import "./StatisticCardInfo.scss";

interface StatisticCardInfoProps {
  image: string;
  title: string;
  value: any;
}

const StatisticCardInfo: React.FC<StatisticCardInfoProps> = ({
  image,
  title,
  value,
}) => {
  return (
    <div className="StatisticCardInfo">
      <img src={image} alt={title} />
      <h2 className="number">{value}</h2>
      <p className="title">{title}</p>
    </div>
  );
};

export default StatisticCardInfo;
