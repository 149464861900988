import { CDN_HOST } from "env/env.dev";

const MarianaSerna      = `${CDN_HOST}/images/MarianaSerna.png`,
      JulianaReyes      = `${CDN_HOST}/images/JulianaReyes.png`,
      ArisSomda         = `${CDN_HOST}/images/ArisSomda.png`,
      SallySantiago     = `${CDN_HOST}/images/SallySantiago.png`,
      AshleyGuerra      = `${CDN_HOST}/images/AshleyGuerra.png`,
      GuillermoSalazar  = `${CDN_HOST}/images/GuillermoSalazar.png`,
      ZamirRedondo      = `${CDN_HOST}/images/ZamirRedondo.png`,
      EfrainHernandez   = `${CDN_HOST}/images/EfrainHernandez.png`,
      CarlosEspinosa   = `${CDN_HOST}/images/JoseCarlosEspinosa.webp`;

const leadershipTeamMock = [
    {
        name: "Mariana Serna",
        position: "marianaCharge",
        image: MarianaSerna
    },
    {
        name: "Juliana Reyes",
        position: "julianaCharge",
        image: JulianaReyes,
    },
    {
        name: "Aris Somda",
        position: "arisCharge",
        image: ArisSomda,
    },
    {
        name: "Sally Santiago",
        position: "sallyCharge",
        image: SallySantiago,
    },
    {
        name: "Ashley Guerra",
        position: "ashleyCharge",
        image: AshleyGuerra,
    },
    {
        name: "Guillermo Salazar",
        position: "guillermoCharge",
        image: GuillermoSalazar,
    }, 
    {
        name: "Zamir Redondo",
        position: "zamirCharge",
        image: ZamirRedondo,
    },
    {
        name: "Efrain Hernandez",
        position: "efrainCharge",
        image: EfrainHernandez
    },
    {
        name: "Jose Carlos Espinosa",
        position: "carlosCharge",
        image: CarlosEspinosa
    }
];

export default leadershipTeamMock;