import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import { Testimonial } from "components/atoms";
import useViewport from "hooks/useViewport";
import { CDN_HOST } from "env/env.dev";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialSlider.scss";

const SlickArrowPrev = ({ currentSlide, slideCount, ...props }) => {
  const [viewport] = useViewport();
  return (
    <button
      {...props}
      className={"prev-arrow"}
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <IoIosArrowBack size={viewport === "phone" ? 20 : 30} />
    </button>
  );
};

const SlickArrowRight = ({ currentSlide, slideCount = 0, ...props }) => {
  const [viewport] = useViewport();

  return (
    <button
      {...props}
      className={
        "next-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <IoIosArrowForward size={viewport === "phone" ? 20 : 30} />
    </button>
  );
};

const TestimonialSlider = () => {
  const { t } = useTranslation(["homeTranslations"]);
  const [viewport] = useViewport();

  const testimonies = [
    {
      id: 1,
      content: t("testimonialContent01"),
      image: `${CDN_HOST}/images/UAM.png`,
    },
    {
      id: 2,
      content: t("testimonialContent02"),
      image: `${CDN_HOST}/images/universidad-del-savador.png`,
    },
    {
      id: 3,
      content: t("testimonialContent03"),
      image: `${CDN_HOST}/images/universidad-san-carlos-guatemala.png`,
    },
    {
      id: 4,
      content: t("testimonialContent04"),
      image: `${CDN_HOST}/images/universidad-nacional-de-ingenieria.png`,
    },
    {
      id: 5,
      content: t("testimonialContent05"),
      image: `${CDN_HOST}/images/universidad-nacional-costa-rica.png`,
    },
    // Agrega los demás testimonios con su contenido e imagen correspondientes
  ];

  const displayArrow = (arrow) =>
    viewport === "phone" || viewport === "tablet" ? false : arrow;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: displayArrow(<SlickArrowRight currentSlide={undefined} />),
    prevArrow: displayArrow(<SlickArrowPrev currentSlide={undefined} slideCount={undefined} />),
  };

  return (
    <div className="TestimonialSlider">
      <div className="wrapper">
        <h2>{t("testimonialsTitle")}</h2>
        <Slider {...settings}>
          {testimonies.map((testimonial) => (
            <Testimonial key={testimonial.id} testimonial={testimonial} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialSlider;
