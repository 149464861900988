import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { firebaseApp, googleAuthProvider, facebookAuthProvider } from "auth";
import { Loading } from "components/atoms";
import { AuthContext } from "context";

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<firebase.User>();
  const [preferences, setPreferences] = useState<string>();
  const [token, setToken] = useState<string>();
  const [authError, setAuthError] = useState<firebase.auth.Error>();
  const [callback, setCallback] = useState<() => void>();
  const [loginWithProvider, setLoginWithProvider] = useState(false);

  const clearStates = () => {
    setIsLoggedIn(false);
    setUser(null);
    setToken(null);
    setCallback(null);
    setLoginWithProvider(false);
    setPreferences(null);
  };

  useEffect(() => {
    const getPreferences = async () => {
      setIsLoading(true);

      const user = await firebaseApp.auth().currentUser;
      console.log("user", user);

      setIsLoading(false);
    };

    if (isLoggedIn && token) {
      getPreferences();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && callback) {
      if (loginWithProvider) {
        firebase
          .auth()
          .getRedirectResult()
          .then((result) => {
            if (result.credential) {
              callback();
            }
          })
          .catch((error) => {
            setAuthError(error);
          });
      } else {
        callback();
      }
    }

    if (authError) {
      setIsLoading(false);
    }
  }, [callback, authError, loginWithProvider, isLoggedIn]);

  useEffect(() => {
    setIsLoading(true);
    clearStates();

    firebaseApp.auth().onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const idTokenResults = await user.getIdTokenResult();
        setToken(`Bearer ${idTokenResults.token}`);
        setIsLoggedIn(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  const logout = (cb) => {
    setIsLoading(true);
    clearStates();
    firebaseApp.auth().signOut();
  };

  const signInWithEmailAndPassword = (email, password, cb) => {
    setIsLoading(true);
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((credentials) => {
        if (credentials) {
          setCallback(cb);
        }
      })
      .catch((error) => setAuthError(error));
  };

  const signInWithGoogle = (cb) => {
    setIsLoading(true);
    setCallback(cb);
    setLoginWithProvider(true);

    firebaseApp.auth().signInWithRedirect(googleAuthProvider);
  };

  const signInWithFacebook = (cb) => {
    setIsLoading(true);
    setCallback(cb);
    setLoginWithProvider(true);

    const provider = facebookAuthProvider;
    provider.addScope("email");
    provider.addScope("public_profile");

    firebaseApp.auth().signInWithRedirect(provider);
  };

  if (!isLoading) {
    return (
      <AuthContext.Provider
        value={{
          isLoggedIn,
          isLoading,
          user,
          token,
          preferences,
          authError,
          logout,
          signInWithEmailAndPassword,
          signInWithGoogle,
          signInWithFacebook,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  } else {
    return <Loading />;
  }
};

export default AuthProvider;
