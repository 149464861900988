import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';

import './MentorSection.scss';
import { Link } from 'react-router-dom';

interface MentorSectionProps {
    formURL?: string
}

const MentorSection = ({
    formURL
}: MentorSectionProps) => {
    const { t } = useTranslation(["members"]);
    const MentorIMG = `${CDN_HOST}/images/mentor.png`;

    return (
        <section className="MentorSection">
            <div className="wrapper">
                <div className="Mentor__content">
                    <h2>{t("mentorTitle")}</h2>
                    <p>{t("mentorTextOne")}</p>
                    <p>{t("mentorTextTwo")}</p>
        
                    <div className="button__container">
                    <p>{t("becomeMentorButton")}</p>
                        <Link to={formURL} className="btn-primary" rel="noopener noreferrer">{t("joinButton")}</Link>
                    </div>
                </div>

                <div className="images-container">
                    <div className="Mentor__image-container">
                        <figure className="Mentor__image">
                            <img src={MentorIMG} loading="lazy" alt="Mentors" />
                        </figure>
                    </div>

                    <div className="techNode-section"></div>
                </div>
            </div>
        </section>
    )
}

export default MentorSection;
