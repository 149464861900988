import React from "react";
import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";



import "./OurVolunteers.scss";

const OurVolunteers: React.FC = () => {
  const { t } = useTranslation(["whoWeAre"]);
  const Collage = `${CDN_HOST}/images/collage.png`;
  return (
    <div className="OurVolunteers">
      <div className="wrapper">
        <h2 className="title">{t("ourVolunteers")}</h2>
        <img src={Collage} alt="Our volunteers" loading="lazy" />
      </div>
    </div>
  );
};

export default OurVolunteers;
