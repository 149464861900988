import { useTranslation } from 'react-i18next';

import './Event.scss';

interface EventProps {
    title?: any,
    description?: any,
    imageEvent?: any,
    date?: any,
    time?: any,
    typeEvent?: any,
    registerURL?: string
}

const Event = ({
    title,
    description,
    imageEvent,
    date,
    time,
    typeEvent,
    registerURL
}: EventProps) => {

    const { t } = useTranslation(["events"]);

    return (
        <div className="Event">
            <div className="Event__content">
                <h5>{title}</h5>
                <p>{description}</p>
                <figure className="Event__image">
                    <img src={imageEvent} alt="Event" width="272" height="180" loading="lazy" />
                </figure>
            </div>
            
            <div className="Event__date">
                <p><span className="icon-event calendar"></span>{date}</p>
                <p><span className="icon-event clock"></span>{time}</p>
                <p><span className="icon-event people"></span>{typeEvent}</p>
            </div>
        </div>
    )
}

export default Event;
