import React from "react";
import { useTranslation } from "react-i18next";

import "./TeamCard.scss";

interface TeamCardProps {
  name: string;
  position: string;
  quote?: string;
  image: string;
}

const TeamCard: React.FC<TeamCardProps> = ({
  name,
  position,
  quote = null,
  image,
}) => {
  const { t } = useTranslation(["whoWeAre"]);

  return (
    <div className="TeamCard">
      <img className="person-image" src={image} alt={name} />
      <h3 className="name">{t(name)}</h3>
      <p className="charge">{t(position)} </p>
      <div className="divider" />
      {quote && (
        <p className="quote">
        <div className="quote-img" />
        {t(quote)}
      </p>
      )}
    </div>
  );
};

export default TeamCard;
