import { useTranslation } from 'react-i18next';

import './ProfileInfo.scss';

import profilePicture from './ProfileImages/profilePicture.png';
import notification from './ProfileImages/notification.png';

const ProfileInfo = () => {
    const { t } = useTranslation(["profile"]);

    return (
        <div className="ProfileInfo">
          <div className="top-container">
          <div className="name-container">
            <p className="name">John Smith</p>
            <img src={ notification } alt="Notification" />
          </div>
          <img src={ profilePicture } alt="Profile" className="profile-image"/>
          <div className="info-container">
            <button>{t("editBtn")}</button>
            <div className="row-1"> 
              <div>
                <p className="text">{t("memberID")}</p>
                <p className="detail">294744830</p>
              </div>
              <div>
                <p className="text">{t("phoneNumber")}</p>
                <p className="detail">809 827 3628</p>
              </div>
              <div>
                <p className="text">{t("email")}</p>
                <p className="detail">something@gmail.com</p>
              </div>
              <div>
                <p className="text">{t("password")}</p>
                <p className="detail">***************</p>
              </div>
            </div>
            <div className="separator"></div>
            <div className="row-2"> 
              <div>
                <p className="text">{t("gender")}</p>
                <p className="detail">Male</p>
              </div>
              <div>
                <p className="text">{t("ethnicity")}</p>
                <p className="detail">Colombian</p>
              </div>
            </div>
            <div className="separator"></div>
            <div className="row-3"> 
              <div>
                <p className="text">{t("country")}</p>
                <p className="detail">United States of America</p>
              </div>
              <div>
                <p className="text">{t("cityAndState")}</p>
                <p className="detail">Clifton, NJ</p>
              </div>
            </div>
            </div>
        </div>
        <div className="bottom-container">
            <button>{t("editBtn")}</button>
            <div className="row-1"> 
              <div>
                <p className="text">{t("university")}</p>
                <p className="detail">University of Saint Luis</p>
              </div>
              <div>
                <p className="text">{t("degree")}</p>
                <p className="detail">BA </p>
              </div>
              <div>
                <p className="text">{t("subject")}</p>
                <p className="detail">Computer Science</p>
              </div>
              <div>
                <p className="text">{t("graduation")}</p>
                <p className="detail">May - 2019</p>
              </div>
            </div>
            <div className="separator"></div>
            <div className="row-2"> 
              <div>
                <p className="text">{t("employer")}</p>
                <p className="detail">KPMG</p>
              </div>
              <div>
                <p className="text">{t("position")}</p>
                <p className="detail">Software Developer</p>
              </div>
            </div>
            </div>
        </div>
    )
};


export default ProfileInfo;