import React, { useState, useEffect, useRef } from "react";
import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";
import { StatisticCardInfo } from "components/molecules";

import "./TheFamily.scss";

const TheFamily: React.FC = () => {
  const { t, i18n: { language } } = useTranslation(["whoWeAre"]);
  const Members = `${CDN_HOST}/images/members.png`;
  const Volunteers = `${CDN_HOST}/images/volunteers.png`;
  const Mentors = `${CDN_HOST}/images/mentors.png`;

  const DTSValues = {
    en: {
      members: "12152",
      mentors: "3",
      volunteers: "21"
    },
    es: {
      members: "12152",
      mentors: "4",
      volunteers: "40"
    }
  };

  const [statisticValues, setStatisticValues] = useState(DTSValues[language]);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [count, setCount] = useState(0);
  const intersectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1 && !animationStarted) {
          setAnimationStarted(true);
          startCounting();
        } else if (!entry.isIntersecting && entry.intersectionRatio === 0 && animationStarted) {
          setAnimationStarted(false);
          setCount(0);
        }
      });
    }, { threshold: 1 });

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) {
        observer.unobserve(intersectionRef.current);
      }
    };
  }, [animationStarted]);

  useEffect(() => {
    setStatisticValues(DTSValues[language]);
    setCount(0); // Reiniciar el conteo al cambiar el idioma
  }, [language]);

  const startCounting = () => {
    const increment = Math.ceil((parseInt(statisticValues.members) - count) / 100);
    const interval = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + increment;
        return newCount >= parseInt(statisticValues.members) ? parseInt(statisticValues.members) : newCount;
      });
    }, 10);

    // Detener la animación cuando se alcanza el valor final
    if (count >= parseInt(statisticValues.members)) {
      console.log("Animation stopped - Value reached the final count");
      clearInterval(interval);
    }
  };

  return (
    <div className="TheFamily" ref={intersectionRef}>
      <div className="wrapper">
        <h2 className="title">{t("theDreamToSucceedFamily")}</h2>
        <div className="cards-container">
          <StatisticCardInfo
            image={Members}
            title={t("members")}
            value={count.toString()}
          />
          <StatisticCardInfo
            image={Volunteers}
            title={t("volunteers")}
            value={statisticValues.volunteers}
          />
          <StatisticCardInfo
            image={Mentors}
            title={t("mentors")}
            value={statisticValues.mentors}
          />
        </div>
      </div>
    </div>
  );
};

export default TheFamily;
