module.exports = {
  WEBSITE_URL: "https://players-dev.ibookweplay.com",
  CDN_HOST: "https://cdn.sonarescrecer.org",
  FIREBASE_API_KEY: "AIzaSyARfiKFPs69OvBzDIpfWHnbJOblog5hMKc",
  FIREBASE_AUTH_DOMAIN: "sonarescrecer-org.firebaseapp.com",
  FIREBASE_PROJECT_ID: "sonarescrecer-org",
  FIREBASE_STORAGE_BUCKET: "sonarescrecer-org.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "699286011426",
  FIREBASE_APP_ID: "1:699286011426:web:82961c6218d8305222947b",
  STRIPE_PUBLISHABLE_KEY: "pk_live_51JiLJ0Eb9e9ammYXITpNigMKgewPBjU0hPDdW4IFDrDsWUWYBAtllJvYbX6KjCGyHs2bfsgmDMfSD4td0D9DqqWm00idxkvOn4",
  RECAPTCHA_PUBLIC_KEY: "6LcmisscAAAAAMJoiDhNFbRWeO8o3mwqKTBQT2LO",
  PAYPAL_CLIENT_ID: "AZAsTpHATaZ95gm1t_IVRfaxaDY2SuZlJRMGTUhKrJJJfu03P4Mo97_2xVb5enIUGhSP3Q4LFyeH8-yI",
  API_URL: "https://api.sonarescrecer.org"
};