import { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import teenagersIMG from './ContactFormImages/teenagers-group.png';
import { useTranslation } from 'react-i18next';
import { RECAPTCHA_PUBLIC_KEY } from 'env/env.dev'
import './ContactForm.scss'; 
import { Navigate } from 'react-router-dom';

type ContactFormValues = {
    fullName: string;
    contactEmail: string;
    subject: string;
    contactMessage: string;
}

const ContactForm = () => {
    
    const { t } = useTranslation(["contact"]);
    const captcha = useRef(null);
    const submitButton = useRef<HTMLButtonElement>();
    const [validCaptcha, setValidCaptha] = useState(null);
    const [validUser, setValidUser] = useState(false);
    const [isCaptchaForm, setIsCaptchaForm] = useState(false); // Define isCaptchaForm state
    const [captchaSize, setCaptchaSize] = useState("compact");
    const { register, formState: { errors }, handleSubmit } = useForm<ContactFormValues>()

    useEffect(() => {
        if(window.matchMedia("(min-width: 992px)").matches) {
            setCaptchaSize("normal");
        } else {
            setCaptchaSize("compact");
        }
    },[]);

    const onChangeCaptcha = () => {
        const formButton = submitButton.current
        if(captcha.current.getValue()) {
            console.log("Usuario Valido");
            setValidCaptha(true);
            formButton.style.opacity = "1";
        }
    }

    const onSubmitForm = async (dataUser) => {
    
        if(captcha.current.getValue()) {
            setValidUser(true);
            setValidCaptha(true);

            if (isCaptchaForm) {
                return <Navigate to="/register" />;
            }
        } else {
            setValidUser(false);
            setValidCaptha(false)
        }
    };


    return (
        <section className="ContactForm">
          {!validUser &&  
            <>
                <h2>{t("contactSubtitle")}</h2>
                <p>{t("contactText")}</p>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <h4>{t("contactFormTitle")}</h4>

                    <label htmlFor="name">{t("nameForm")}</label>
                    
                    {errors.fullName && <p>{t("inputRequire")}</p>}
                    <input 
                        type="text" 
                        id="name" 
                        name="name"
                        {...register("fullName", { required: true })} />

                    <label htmlFor="email">{t("emailForm")}</label>
                    
                    {errors.contactEmail && <p>{t("inputRequire")}</p>}   
                    <input 
                        type="email"
                        id="email"
                        name="email" 
                        {...register("contactEmail", { required: true })} />

                    <label htmlFor="subject">{t("subjectForm")}</label>
                    
                    {errors.subject && <p>{t("inputRequire")}</p>}
                    <input 
                        type="text"
                        id="subject"
                        name="subject"
                        {...register("subject", { required: true })} />

                    <label htmlFor="message">{t("messageForm")}</label>
                    
                    {errors.contactMessage && <p>{t("inputRequire")}</p>}
                    <textarea 
                        name="message"
                        id="message" 
                        placeholder={t("placeholderForm")}
                        {...register("contactMessage", { minLength: 10, required: true })}></textarea>

                    <div className="captcha-container">
                        <div className="captcha">
                        {validCaptcha === false && <div className="captcha-error">{t("errorCaptcha")}</div>}
                            <ReCAPTCHA 
                                ref={captcha}
                                sitekey={RECAPTCHA_PUBLIC_KEY}
                                onChange={onChangeCaptcha}
                                size="normal"
                            />
                        </div>
                       <button ref={submitButton} disabled={validUser} className="btn-primary" type="submit">{t("submitButtonForm")}</button>
                    </div>
                </form>
            </>}
            {validUser &&  
                <>
                    <h2>{t("formSentTitle")}</h2>
                    <p>{t("formSentText")}</p>
                    <figure className="ContactForm__Img">
                        <img src={teenagersIMG} alt="Thaks for contact us - dream to suceed" width="120" height="200" loading="lazy" />
                    </figure>
                </>}
                
        </section>
    )
}

export default ContactForm;
