import { CDN_HOST } from "env/env.dev";
import React from "react";
import { useTranslation } from "react-i18next";

import "./WeAre.scss";

const WeAre: React.FC = () => {
  const { t } = useTranslation(["whoWeAre"]);
  const SectionOneImage = `${CDN_HOST}/images/woman-handsup.png`;
  return (
    <div className="WeAre">
      <div className="wrapper">
        <img src={SectionOneImage} alt={t("whoWeAre")} className="we-are-img" />
        <div className="info">
          <span>{t("whoWeAre")}</span>
          <h2 className="title">{t("weAreDreamToSucceed")}</h2>
          <p>{t("description1")}</p>
          <p>{t("description2")}</p>
        </div>
      </div>
    </div>
  );
};

export default WeAre;
