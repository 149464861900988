import React, { useState, useEffect, useRef, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UserPicture } from 'components/atoms';
import { Link } from 'react-router-dom';
import { CDN_HOST } from 'env/env.dev';
import { AuthContext } from 'context';

import './Header.scss';

const viewTitles = ["members", "donations", "events", "work with us", "who we are", "contact us", "news"];

interface HeaderProps {
    pageTitle?: string;
    pageView?: string;
    user?: boolean;
    dark?: boolean;
}

const Header = ({ 
    pageTitle, 
    pageView = "home",
    dark = false
 }: HeaderProps) => {

    const LogoIMG           = `${CDN_HOST}/icons/icon-logo-desktop.svg`,
          LogoDarkIMG       = `${CDN_HOST}/icons/icon-logo-desktop-dark.svg`,
          LogoMobileIMG     = `${CDN_HOST}/icons/icon-logo-mobile.svg`,
          LogoMobileDarkIMG = `${CDN_HOST}/icons/icon-logo-mobile-dark.svg`,
          menuOpenIcon      = `${CDN_HOST}/icons/icon-menu.svg`,
          menuOpenIconDark  = `${CDN_HOST}/icons/icon-menu-icon-dark.svg`,
          menuCloseIcon     = `${CDN_HOST}/icons/icon-menu-close.svg`,
          usaIMG            = `${CDN_HOST}/images/united-states.png`,
          spainIMG          = `${CDN_HOST}/images/spain.png`,
          menuArrowIMG      = `${CDN_HOST}/icons/icon-menu-arrow.svg`,
          menuArrowDarkIMG  = `${CDN_HOST}/icons/icon-menu-arrow-dark.svg`;
      
    const {isLoggedIn, logout} = React.useContext(AuthContext),
          { t, i18n } = useTranslation(["pageTitles"]),
          [logo, setLogo] = useState(LogoIMG),
          [logoMobile, setLogoMobile] = useState(LogoMobileIMG),
          [isMenuOpen, setIsMenuOpen] = useState(false),
          [dropdownMenuOpen, setDropdownMenuOpen] = useState(false),
          [menuIMG, setMenuIMG] = useState(menuOpenIcon),
          [menuArrow, setMenuArrow] = useState(menuArrowIMG),
          [laguageIMGPreselected, setLanguageIMGPreselected] = useState(''),
          [languageIMGSelected, setLanguageIMGSelected] = useState('');

    const navigationFloat = useRef<HTMLElement>();
    const listItemOne =  useRef<HTMLLIElement>();
    const listItemTwo =  useRef<HTMLLIElement>();
    const menu = useRef<HTMLSpanElement>();
    const iconMenu = useRef<HTMLElement>();
    const languageFlagSelection = useRef<HTMLElement>();
    const registerButtonRef = useRef<HTMLAnchorElement>();
    const viewOptionsRef =  useRef<any[]>(
        Array(7)
            .fill(0)
            .map(() => createRef())
    );

    useEffect(() => {
        if(i18n.language === "en") {
            setLanguageIMGPreselected(usaIMG);
            setLanguageIMGSelected(spainIMG);
        } else if(i18n.language === "es") {
            setLanguageIMGPreselected(spainIMG)
            setLanguageIMGSelected(usaIMG);
        }
    }, [i18n, usaIMG, spainIMG]);

    useEffect(() => {
        if(dark) {
            setLogo(LogoDarkIMG);
            setLogoMobile(LogoMobileDarkIMG);
            setMenuIMG(menuOpenIconDark);
           if(window.matchMedia("(min-width: 992px)").matches) {
            setMenuArrow(menuArrowDarkIMG);
           }
        } else {
            setLogo(LogoIMG);
            setLogoMobile(LogoMobileIMG);
            setMenuIMG(menuOpenIcon);
            setMenuArrow(menuArrowIMG);
        }
            const header = document.querySelector('header');
            const arrowSpan = header.querySelectorAll('span');
            arrowSpan.forEach(spanItem => spanItem.style.backgroundImage = `url(${menuArrow})`);
    },[dark, menuArrow]);

    const handleMenuOpen = () => {
        if(navigationFloat.current) {
            const navMenu = navigationFloat.current,
                  menuIcon = menu.current,
                  menuSVG = iconMenu.current,
                  languageSelection = languageFlagSelection.current;  

            if(navMenu.classList.contains('isOpen')) {
                navMenu.classList.remove('isOpen');
                menuIcon.style.position =  'relative';
                menuIcon.style.right = '0';
                menuIcon.style.top = '0';
                menuSVG.style.width = '30px';
                menuSVG.style.height = '30px';
                languageSelection.style.display = 'none';
                setIsMenuOpen(false);
            } else {
                navMenu.classList.add('isOpen');
                menuIcon.style.position = 'fixed';
                menuIcon.style.right = '38px'; 
                menuIcon.style.top = '30px';
                menuSVG.style.width = '22px';
                menuSVG.style.height = '22px';
                setIsMenuOpen(true);
            }
        }
    };

    const handleLanguageSelection = () => {
        const laguageSelection = languageFlagSelection.current;
        laguageSelection.style.display = 'block';
    };

    const handleLanguageSelected = () => {
        const languageSelection = languageFlagSelection.current;

        if(languageIMGSelected === spainIMG) {
            setLanguageIMGPreselected(spainIMG);
            setLanguageIMGSelected(usaIMG);
            i18n.changeLanguage("es");
        } else {
            setLanguageIMGPreselected(usaIMG);
            setLanguageIMGSelected(spainIMG);
            i18n.changeLanguage("en");
        }

        languageSelection.style.display = 'none';
    };

    useEffect(() => {
        if(dark) {
            isMenuOpen 
                ? setMenuIMG(menuCloseIcon) 
                : setMenuIMG(menuOpenIconDark);
        } else {
            isMenuOpen 
            ? setMenuIMG(menuCloseIcon) 
            : setMenuIMG(menuOpenIcon);
        }
    }, [isMenuOpen, dark]);

    useEffect(() => {

        const navElement =  navigationFloat.current,
              navList = navElement.querySelector('ul'),
              navButtons = navElement.querySelectorAll('a'),
              flagButtons = navElement.querySelectorAll('figure'),
              registerButton = registerButtonRef.current;

        if(window.matchMedia("(min-width: 992px)").matches) {
           if(isLoggedIn) {
            if(dark) {
                if(navigationFloat.current) {
                    navButtons.forEach(button  => button.style.color = "#000000");
                    flagButtons.forEach(flag => flag.style.borderColor = "#000000");
                    navList.style.borderRightColor = "#000000";
                } 
            } else {
                if(navigationFloat.current) {
                    navButtons.forEach(button  => button.style.color = "#FFFFFF");
                    flagButtons.forEach(flag => flag.style.borderColor = "#FFFFFF");
                    navList.style.borderRightColor = "#FFFFFF";
                }

            }
           } else {
            if(dark) {
                if(navigationFloat.current) {
                    navButtons.forEach(button  => button.style.color = "#000000");
                    registerButton.style.color = '#FFFFFF';
                    registerButton.style.backgroundColor = '#D62246'
                    flagButtons.forEach(flag => flag.style.borderColor = "#000000");
                    navList.style.borderRightColor = "#000000";
                } 
            } else {
                if(navigationFloat.current) {
                    navButtons.forEach(button  => button.style.color = "#FFFFFF");
                    registerButton.style.color = '#D62246';
                    flagButtons.forEach(flag => flag.style.borderColor = "#FFFFFF");
                    navList.style.borderRightColor = "#FFFFFF";
                }
            }

           }
        } else if(window.matchMedia("(max-width: 992px)").matches) {
                const header = document.querySelector('header'),
                      title = header.querySelector('h1');
            
                dark
                   ? title.style.color = "#000000"
                   : title.style.color = "#FFFFFF";
            
            
        }

       
    },[dark, isLoggedIn]);

    useEffect(() => {

        if(pageView !== "home") {
            const indexView = viewTitles.indexOf(pageView);
            const navigationOptionsRef =  viewOptionsRef.current;
            const navListElements = navigationOptionsRef.map(
                (ref) => ref.current
            );

            const view = navListElements[indexView];
            const linkElement = view.querySelector('a');
            linkElement.style.color = '#D62246';
            view.style.borderBottom = '4px solid #D62246';
        } 
        

    },[pageView]);

    const onSubmenuOpen = (element) => {
        const listItem = element.current;
        const submenu =  listItem.querySelector('ul');
        const dropdownArrow =  listItem.querySelector('span');

        submenu.style.display = 'block';
        dropdownArrow.style.transform ='rotateZ(180deg)';
        setDropdownMenuOpen(true);
    }

    const onSubmenuClosed = (element) => {
        const listItem = element.current;
        const submenu =  listItem.querySelector('ul');
        const dropdownArrow =  listItem.querySelector('span');

        submenu.style.display = 'none';
        dropdownArrow.style.transform ='rotateZ(0deg)';
        setDropdownMenuOpen(false);
    }

    const handleDropdownMenu = (element) => {
        if(dropdownMenuOpen){
            onSubmenuClosed(element);
        } else {
            onSubmenuOpen(element);
        }
    }

    
    return (
        <header className="Header">
            <div className="wrapper">
                
                <div className="header__logo-container">
                    <Link aria-label='Dream to succeed home' to="/" rel="noopener noreferrer">
                        <figure className="Header__logo">
                            <picture>
                                <source media="(min-width: 992px)" srcSet={logo} />
                                <img src={logoMobile} alt="Dream to Succeed" width="50" height="60" loading="lazy" />
                            </picture>
                        </figure>
                    </Link>

                    <h1>{pageTitle}</h1>

                    <i className="Header__menu-icon" ref={menu}>
                        <figure className="menu-icon" ref={iconMenu}>
                            <img onClick={handleMenuOpen}  src={menuIMG} alt="open menu" width="30" height="30" loading="lazy" />
                        </figure>
                    </i>
                </div>

               <div className="Header__menu-wrapper"> 
                    <nav className="Header__navigation-menu" role="navigation" ref={navigationFloat}>
                        <ul>
                            <li><Link aria-label='Home' to="/" rel="noopener noreferrer">{t("headerHome")}</Link></li>
                            <li ref={viewOptionsRef.current[0]} 
                                onMouseOver={() => onSubmenuOpen(viewOptionsRef.current[0])} 
                                onMouseLeave={() => onSubmenuClosed(viewOptionsRef.current[0])}><a href="#id"   aria-label="Members" rel="noopener noreferrer">{t("headerMembers")}<span onClick={() => handleDropdownMenu(listItemOne)} className="menu-down"></span></a>
                                <ul>
                                    <li><Link to="/members/dreamer" aria-label='Dreamer' rel="noopener noreferrer">{t("headerDreamer")}</Link></li>
                                    <li><Link to="/members/mentor" aria-label='Mentor' rel="noopener noreferrer">{t("headerMentor")}</Link></li>
                                    <li><Link to="/members/volunteer" aria-label='Volunteer' rel="noopener noreferrer">{t("headerVolunteer")}</Link></li>
                                </ul>
                            </li>
                            <li ref={viewOptionsRef.current[1]}
                                onMouseOver={() => onSubmenuOpen(viewOptionsRef.current[1])} 
                                onMouseLeave={() => onSubmenuClosed(viewOptionsRef.current[1])}><a href="#id"   aria-label="Donations" rel="noopener noreferrer">{t("headerDonations")}<span onClick={() => handleDropdownMenu(listItemOne)} className="menu-down"></span></a>
                                <ul>
                                    <li><Link to="/donations" aria-label='Dreamer' rel="noopener noreferrer">{t("headerDonations")}</Link></li>
                                    <li><Link to="https://dream-to-succeed-foundation.raisely.com/" aria-label='Mentor' rel="noopener noreferrer" target="_blank">{t("headerCampaigns")}</Link></li>
                                </ul>
                            </li>
                            <li ref={viewOptionsRef.current[2]}><Link to="/events" aria-label='events' rel="noopener noreferrer">{t("headerEvents")}</Link></li>
                            <li ref={viewOptionsRef.current[3]}
                                onMouseOver={() => onSubmenuOpen(viewOptionsRef.current[3])}
                                onMouseLeave={() => onSubmenuClosed(viewOptionsRef.current[3])}><a href="#id" aria-label='Work with us'>{t("headerWork")}<span onClick={() => handleDropdownMenu(listItemTwo)} className="menu-down"></span></a>
                                <ul>
                                    <li><Link to="/partners-and-sponsors"  aria-label='partners and sponsors'rel="noopener noreferrer">{t("headerPartnersAndSponsors")}</Link></li>
                                    <li><Link to="/work-with-us/our-programs" aria-label='our programs' rel="noopener noreferrer">{t("headerPrograms")}</Link></li>
                                </ul>
                            </li>
                            <li ref={viewOptionsRef.current[4]}><Link to="/who-we-are" aria-label='Who we are' rel="noopener noreferrer">{t("headerWhoWeAre")}</Link></li>
                            <li ref={viewOptionsRef.current[5]}><Link to="/contact-us" aria-label='Contact us' rel="noopener noreferrer">{t("headerContact")}</Link></li>
                            <li ref={viewOptionsRef.current[6]}><Link to="/news" aria-label='news' rel="noopener noreferrer">{t("headerNews")}</Link></li>
                        </ul>

                        <div className="navigation__login">
                            {isLoggedIn ? 
                            <> 
                                <UserPicture />  
                                <p className="logout" onClick={() => logout()}>{t("headerLogout")}</p>
                            </>
                             : 
                            <>
                                <Link to="/register" ref={registerButtonRef} className="btn-secundary" aria-label='Register' rel="noopener noreferrer">{t("headerRegister")}</Link>
                                <a className="login-button" aria-label='Login' href="#id" rel="noopener noreferrer">{t("headerLogin")}</a>
    
                            </>
                            }
                            <div className="language-wrapper">
                                <figure onClick={handleLanguageSelection} className="language-selection">
                                    <img src={laguageIMGPreselected} alt="English language" width="24" height="24" loading="lazy" />
                                </figure>
                                <figure onClick={handleLanguageSelected} className="language-selection" ref={languageFlagSelection}>
                                    <img src={languageIMGSelected} alt="Spanish language" width="24" height="24" loading="lazy"/>
                                </figure>
                            </div>
                    </div>
                    </nav>
                    
               </div>
            </div>
            
        </header>
    )
}

export default Header;
