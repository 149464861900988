import './MegaEvent.scss';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';

const MegaEvent = () => {
    const { t } = useTranslation(["homeTranslations"]);
  return (
    <section style={{ backgroundImage: `url(${CDN_HOST}/images/megaevent-hero-blue.png)`}} className='MegaEvent'>
        <div className="Event-content">
            <h1>{t("eventsSubtitle")} {t("eventsSubtitleSecond")}</h1>
            <div className="Event_coming-soon">
            <p>{t("eventsSoon")}</p>
          </div>
        </div>
    </section>
  )
}

export default MegaEvent;