import React, { useState, useLayoutEffect } from "react";
import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";
import { SocialMediaCard } from "components/molecules";

import "./SocialMedia.scss";

const SocialMedia: React.FC = () => {
  const { t, i18n: { language} } = useTranslation(["whoWeAre"]);
  const  Instagram = `${CDN_HOST}/images/instagram.png`;
  const  Facebook  = `${CDN_HOST}/images/facebook.png`;
  const  Twitter   = `${CDN_HOST}/images/twitter-x-logo-circle.webp`;
  const  LinkedIn  = `${CDN_HOST}/images/linkedin.png`;
  const SocialMediaLinksES = {
    instagram: {
      link: "https://www.instagram.com/sonarescrecer/",
      followers: 327
    },
    facebook: {
      link: "https://www.facebook.com/sonarescrecerorg/",
      followers: 88,
    },
    twitter: {
      link: "https://twitter.com/sonarescrecer",
      followers: 95,
    },
    linkedin: {
      link: "https://www.linkedin.com/company/fundaci%C3%B3n-so%C3%B1ar-es-crecer/",
      followers: 338
    }
  }
  const SocialMediaLinksEN = {
    instagram: {
      link: "https://www.instagram.com/dreamtosucceedus",
      followers: 1603
    },
    facebook: {
      link: "https://www.facebook.com/dreamtosucceedus1",
      followers: 885
    },
    twitter: {
      link: "https://twitter.com/DREAMTOSUCCEED",
      followers: 90
    },
    linkedin: {
      link: "https://www.linkedin.com/company/dream-to-succeed-us/",
      followers: 1832
    }
  }

  const [socialMedia, setSocialMedia] = useState(SocialMediaLinksEN);

  useLayoutEffect(() => {
    (language === "es") 
      ? setSocialMedia(SocialMediaLinksES)
      : setSocialMedia(SocialMediaLinksEN); 
  }, [language]);

  return (
    <div className="SocialMedia">
      <h2 className="title">{t("findUsOnSocialMedia")}</h2>
      <div className="cards-container">
        <SocialMediaCard
          icon={LinkedIn}
          iconName="Linkedin"
          link={socialMedia.linkedin.link}
          numberOfFollowers={socialMedia.linkedin.followers}
        />
        <SocialMediaCard
          icon={Instagram}
          iconName="Instagram"
          link={socialMedia.instagram.link}
          numberOfFollowers={socialMedia.instagram.followers}
        />
        <SocialMediaCard
          icon={Facebook}
          iconName="Facebook"
          link={socialMedia.facebook.link}
          numberOfFollowers={socialMedia.facebook.followers}
        />
        <SocialMediaCard
          icon={Twitter}
          iconName="Twitter"
          link={socialMedia.twitter.link}
          numberOfFollowers={socialMedia.twitter.followers}
        />
      </div>
    </div>
  );
};
export default SocialMedia;
