import { useTranslation } from 'react-i18next';

import './ContributionsProfile.scss';

const ContributionsProfile = () => {
    const { t } = useTranslation(["profile"]);

    return (
        <div className="ContributionsProfile">
          <h4 className="title">{t("myContributions")}</h4>
          <div className="info-container">
            <div className="col">
              <p className="subtitle">{t("volunteeringTime")}</p>
              <p className="info">35 {t("hours")}</p>
              <p className="description">{t("since")} Sep, 2019</p>
              <button>{t("logHoursBtn")}</button>
            </div>
            <div className="col">
            <p className="subtitle">{t("mentoring")}</p>
              <p className="info">0 {t("hours")}</p>
            </div>
            <div className="col">
            <p className="subtitle">{t("donations")}</p>
              <p className="info">$1398.56</p>
              <p className="description">{t("since")} Sep, 2019</p>
            </div>
          </div>
        </div>
    )
};


export default ContributionsProfile;