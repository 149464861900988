import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';
import './SuccessRegistration.scss';

interface SuccessRegistrationProps {
  member: string;
}

const SuccessRegistration: React.FC<SuccessRegistrationProps> = ({ member }) => {
  const { t } = useTranslation(["register"]);

  return (
    <div className="SuccessRegistration">
        <picture>
          <source media='(min-width: 990px)' srcSet={`${CDN_HOST}/images/close-up-young-girl.png`}/>
          <img src={`${CDN_HOST}/images/young-hispanic-man.png`} alt='Youn girl' loading='lazy' decoding='async' />
        </picture>
        <div className="success-content">
          <h3>{`${t("successTitle")} ${t(member.toLowerCase())}`}</h3>
          <p>{t("successContent")}</p>
        </div>
    </div>
  )
}

export default SuccessRegistration