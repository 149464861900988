import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "context";

import "./LoginForm.scss";
import Info from "./LoginIcons/info.svg";

type FormData = {
  email: string;
  password: string;
};

const LoginForm: React.FC = () => {
  const { t } = useTranslation(["login"]);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const { signInWithFacebook, signInWithGoogle, signInWithEmailAndPassword } =
    React.useContext(AuthContext);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    console.log(data.email, data.password);
    signInWithEmailAndPassword(data.email, data.password, () => navigate("/"));
  };

  const handleLoginWithFacebook = () => {
    signInWithFacebook(() => navigate("/"));
  };

  const handleLoginWithGoogle = () => {
    signInWithGoogle(() => navigate("/"));
  };

  return (
    <form className="LoginForm" onSubmit={handleSubmit(onSubmit)}>
      <h2 className="title">{t("loginTitle")}</h2>
      <label htmlFor="email" className="subtitle">
        {t("email")}
      </label>
      <input
        name="email"
        type="email"
        {...register("email", { required: true })}
        defaultValue={watch("email")}
      />
      {errors.email && <p>{t("emailRequired")}</p>}
      <label htmlFor="password" className="subtitle">
        {t("password")}
      </label>
      <input
        name="password"
        type="password"
        {...register("password", { required: true })}
        defaultValue={watch("password")}
        className="password"
      />
      <img src={Info} alt="info" />
      {errors.password && <p>{t("passwordRequired")}</p>}
      <span className="forgot-text">{t("forgotPassword")}</span>
      <button type="submit">{t("loginBtn")}</button>
      <div className="social-login">
        <button onClick={handleLoginWithGoogle}>Google</button>
        <button onClick={handleLoginWithFacebook}>Facebook</button>
      </div>
    </form>
  );
};

export default LoginForm;
