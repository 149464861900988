import React from "react";

interface AuthContextProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  user: firebase.default.User;
  token: string;
  authError: firebase.default.auth.Error;
  preferences: string;
  setHasPreferences?: React.Dispatch<React.SetStateAction<boolean>>;
  authProvider?: string;
  logout: (cb?: (args: any) => void) => void;
  signInWithEmailAndPassword: (
    email: string,
    password: string,
    cb?: (args: any) => void
  ) => void;
  signInWithGoogle: (cb?: (args: any) => void) => void;
  signInWithFacebook: (cb?: (args: any) => void) => void;
}

const AuthContext = React.createContext<AuthContextProps>(null);

export default AuthContext;
