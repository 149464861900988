import React from "react";
import { useTranslation } from "react-i18next";
import { CDN_HOST } from "env/env.dev";

import "./Awards.scss";

const Awards: React.FC = () => {
  const { t } = useTranslation(["whoWeAre"]);
  const Image1           = `${CDN_HOST}/images/top-rated.png`,
        Image2           = `${CDN_HOST}/images/guide-star.png`,
        Star             = `${CDN_HOST}/images/star.png`,
        GreatNonprofits  = `${CDN_HOST}/images/great-nonprofits.png`,
        Logo             = `${CDN_HOST}/icons/icon-logo-desktop-dark.svg`;

  return (
    <div className="Awards">
      <h2 className="title">{t("awards")}</h2>
      <div className="section one">
        <img className="img-1" src={Image1} alt="award" />
        <img className="img-2" src={Image2} alt="award" />
      </div>
      <div className="section two">
        <div className="average-reviews">
          <img className="star" src={Star} alt="star" />
          <p className="rating">5.0 {t("averageRating")}</p>
          <p className="reviews">19 {t("reviews")}</p>
        </div>
        <a href="https://greatnonprofits.org/org/dream-to-succeed-foundation-inc" rel="noopener noreferrer" target="_blank" className="btn">{t("reviewAndRate")}</a>
        <div className="extra-info">
          <img className="logo" src={Logo} alt="Logo Dream to Succeed" />
          <p>On</p>
          <img
            className="nonprofits"
            src={GreatNonprofits}
            alt="great nonprofits"
          />
        </div>
      </div>
    </div>
  );
};

export default Awards;
