import React from "react";
import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classnames from "classnames";

import "./HomeDonation.scss";



interface HomeDonationProps {
  position?: string;
}

const HomeDonation: React.FC<HomeDonationProps> = ({ position }) => {
  const { t } = useTranslation(["homeTranslations"]);
  const donationImage = `${CDN_HOST}/images/hands-right.png`;

  return (
    <section
      className={classnames("HomeDonation", {
        left: position === "left",
        right: position === "right",
      })}
    >
      <h3>{t("donateTitle")}</h3>
      <figure className="HomeDonation__image">
        <img src={donationImage} alt="Hands holding red heart" width="457" height="350" loading="lazy" />
      </figure>
      <div className="HomeDonation__content">
        <p>{t("donateContent")}</p>
        <Link
          to="/donations"
          className="btn-primary "
          rel="noopener noreferrer"
        >
          {t("donateButton")}
        </Link>
      </div>
    </section>
  );
};

export default HomeDonation;
